const ROUTE_NAME = {
	BASE: 'route_name.base',
	ERROR: 'route_name.error',
	CUSTOMER_CHECKLIST: {
		INDEX: 'route_name.customer_checklist.index',
		DOWNLOAD: 'route_name.customer_checklist.download',
	},
	TERM_OF_SERVICE: {
		INDEX: 'route_name.term_of_service.index',
	},
	REDIRECT: 'route_name.redirect',
	SIGNATURE: {
		INDEX: 'route_name.signature_index',
	},
	APP_DOWNLOAD: {
		INDEX: 'route_name.app_download.index',
	},
}

export default ROUTE_NAME
