import type { RouteRecordRaw } from 'vue-router'

import ROUTE_NAME from '@/router/routeName.ts'

export const appDownloadRoutes: RouteRecordRaw[] = [
	{
		component: async () => import('@/modules/app-download/views/AppDownloadView.vue'),
		name: ROUTE_NAME.APP_DOWNLOAD.INDEX,
		path: '/invite/:id*',
	},
]
