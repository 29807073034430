import type { RouteRecordRaw } from 'vue-router'

import ROUTE_NAME from '@/router/routeName.ts'

export const signatureRoutes: RouteRecordRaw[] = [
	{
		component: async () => import('@/modules/signature/features/index/SignatureOverviewView.vue'),
		name: ROUTE_NAME.SIGNATURE.INDEX,
		path: 'sign-document',
	},
]
